import React, { useState, useEffect, useContext, useMemo } from 'react'
import Dialog from '#Dialog'

import APICtx from '#api'
import DataList from '#DataList'
import { getActionDef } from './schemas'


const useTableDef = type => {
	return useMemo(() => {
		return [
			{ label:"Data/ora", field:'_created', type:'datetime' },
			{ label:"Utente", field:'_createdBy', type:'user' },
			{ label:"Azione", content:row => {
				return getActionDef(type, row.action)?.label || '?'
			}},
		]
	}, [ type ])
}

const Checklist = ({ _registry, _id, type, handleClose }) => {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const tableDef = useTableDef(type)

	useEffect(() => {
		_id && api.call('workflow/event/list', { _id }).then(setIds)
	}, [ _id ])

	const getRow = eventID => api.call('workflow/event/get', { _id:eventID })

	return (
		<Dialog
			handleClose={handleClose}
			open={_id}
			title={(_registry?.number || '') + " - Riepilogo eventi"}
			width="md"
		>
			<DataList def={tableDef} ids={ids} getRow={getRow} />
		</Dialog>
	)
}
export default Checklist
