import React, { useEffect, useState, useContext } from 'react';
import { Typography } from '@mui/material';

import APICtx from '#api'
import Form, { Select, Checkbox } from '#Form'

const companyTypes = [
	{ value:'upa', label:'UPA' },
	{ value:'srl', label:'SRL' },
]

export default function CampaignEdit({ _id }) {
	const api = useContext(APICtx)
	const [ latePaymentsOpts, setLatePaymentOpts ] = useState([])
	const [ associateTypesOpts, setAssociateTypesOpts ] = useState([])

	useEffect(() => {
		api.call('late-payments-state/options').then(setLatePaymentOpts)
		api.call('associate-type/options').then(setAssociateTypesOpts)
	}, [])

	const isRW = !_id

	const formDef = {
		_id,
		callSet: isRW && "mailer/contabili/create",
		callGet: _id && "mailer/contabili/get",
		extraData: { type:'contabili' },
	}

	return (
		<Form {...formDef}>
			<Select label="Società" name="companyType" options={companyTypes} emptyLabel="-- seleziona una società --" disabled={!isRW} />
			<Typography variant='h6'>Tipi socio</Typography>
			{ associateTypesOpts.map(
				associateType => <Checkbox label={associateType.label} name={"associateTypes." + associateType.value} disabled={!isRW} />
			)}
			<Typography variant='h6'>Morosità</Typography>
			{ latePaymentsOpts.map(
				latePaymentsOpt => <Checkbox label={latePaymentsOpt.label} name={"latePayments." + latePaymentsOpt.value} disabled={!isRW} />
			)}
		</Form>
	)
}
