import React, { useEffect, useState, useContext } from 'react';

import APICtx from '#api'
import Form, { FileUpload, Input, Select } from '#Form'
import { delegheTypes } from './def';

export default function CampaignEdit({ _id }) {
	const api = useContext(APICtx)
	const [ zones, setZones ] = useState([])

	const formDef = {
		_id,
		callSet: _id ? "mailer/deleghe/update" : "mailer/deleghe/create",
		callGet: _id && "mailer/deleghe/get",
	}

	useEffect(() => {
		api.auth.groupsAsOptions({ acl:'mailer-deleghe-zone' }).then(opts => {
			setZones(opts.filter(opt => api.auth.groups.includes(opt.address)))
		})
	}, [])

	const isRO = Boolean(_id)

	return (
		<Form {...formDef}>
			<FileUpload label="File deleghe F24" name="f24Full" tokenEndpoint="mailer/get-upload-token" disabled={isRO} />
			<Select name="zone" label="Zona assegnata" options={zones} emptyLabel="-- seleziona una zona --" disabled={isRO} />
			<Select name="delegheType" label="Tipologia delega" options={delegheTypes} emptyLabel="-- seleziona una tipologia --" disabled={isRO} />
			<Input name="mailSubject" label="Email - oggetto" />
			<Input name="mailContent" label="Email - testo" multiline />
		</Form>
	)
}
