import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText, SearchDateInt, SearchSelect } from '#Search'
import DataList from '#DataList'
import Dialog from '#Dialog'
import ContractEdit from './Edit'
import Fab from '#Fab'

import { faPlus, faEdit, faMagnifyingGlass, faFilePdf, faTrash, faNote } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from '@mui/material';

const EditDialog = ({ edit, handleEditClose, types, associations, zones }) => (
	<Dialog
		handleClose={handleEditClose}
		open={edit!==null}
		title={edit?._registry?.number ? "Modifica contratto: " + edit._registry.number : 'Nuovo contratto'}
		width="md"
	>
		{ edit!==null && <ContractEdit _id={edit._id} types={types} associations={associations} zones={zones} /> }
	</Dialog>
)

export default function ContractsList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ searchStipulatedInt, setSearchStipulatedInt ] = useState('')
	const [ searchZone, setSearchZone ] = useState('')
	const [ edit, setEdit ] = useState(null)
	const [ types, setTypes ] = useState([])
	const [ associations, setAssociations ] = useState([])
	const [ zones, setZones ] = useState([])

	const handleDelete = ({ _id, _registry }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il contratto "' + _registry?.number + '"?',
			onConfirm: () => api.call('contracts/del', { _id }).then(handleRefresh),
		})
	}
	const handleEditClose = () => {
		setEdit(null)
		handleRefresh()
	}
	const handleRefresh = () =>
		api.call('contracts/list', { searchString, searchStipulatedInt, searchZone }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ searchString, searchStipulatedInt, searchZone ])
	useEffect(() => {
		api.call('contracts/types/options').then(setTypes)
		api.call('contracts/associations/options').then(setAssociations)
		api.call('zone/options').then(setZones)
	}, []) 

	const tableDef = [
		{ label:"Numero", content:row => (
			<>
				<Typography>{row._registry?.number}</Typography>
				{ Boolean(row.repertory) && <Typography variant="caption">Rep: {row.repertory}</Typography> }
			</>
		)},
		{ label:"Stipula", field:'stipulatedOn', type:'date' },
		{ label:"Scadenza", field:'expiresOn', type:'date' },
		{ label:"Proprietà", field:'owner' },
		{ label:"Affittuale", field:'renter' },
		{ label:"Tipologia", content:row => row?.type ? (types?.find(item => item.value===row.type)?.label || '???') : '-'},
		{ label:"Associazione", content:row => row?.association ? (associations?.find(item => item.value===row.association)?.label || '???') : '-'},
		{ label:"Zona", content:row => row?.zone ? (zones?.find(item => item.value===row.zone)?.label || '???') : '-'},
		{ label:"PTA", field:'pta', type:'boolean' },
		{ label:"ACD", field:'acd', type:'boolean' },
		{ label:"Tel.", content:row => !row.telematicRegNumber ? '-' : (
			<>
				<Typography>{row.telematicRegNumber}</Typography>
				<Typography variant="caption">{row.telematicRegDate.split('-').reverse().join('/')}</Typography>
			</>
		)},
		{ type:'icon', content: row => Boolean(row.notes) && faNote },
	]

	const getRow = _id => api.call('contracts/get', { _id })
	const defBtns = [
		{ icon:faEdit, label:'Modifica', onClick:setEdit },
		{
			icon: faMagnifyingGlass,
			label: 'Visualizza PDF',
			onClick: row => api.storage.download(row.fileJWT, true),
			show: row => Boolean(row.fileJWT),
		},
		{
			icon: faFilePdf,
			label: 'Scarica PDF',
			onClick: row => api.storage.download(row.fileJWT, false),
			show: row => Boolean(row.fileJWT),
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]
	
	return (
		<Tpl title="Contratti">
			<EditDialog edit={edit} handleEditClose={handleEditClose} types={types} associations={associations} zones={zones} />
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setSearchString} />
				<SearchDateInt label="Stipula" name="src-text" onChange={setSearchStipulatedInt} />
				<SearchSelect label="Zona" name="src-zone" onChange={setSearchZone} options={zones} emptyLabel="-- Tutte --" />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			<Fab label="Crea un'offerta" icon={faPlus} onClick={() => setEdit({})} />
		</Tpl>
	)
}
