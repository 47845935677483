import React, { useState, useContext } from 'react'
import Form, { Checkbox, Autocomplete, Input, FileUpload, InputNumber } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

export const RecessoSocioCreate = ({ open, handleClose }) => {
	const api = useContext(APICtx)

	const formDef = {
		callSet: "workflow/recesso-socio/create",
	}

	const getAssociatesOpts = async (filter) =>
		api.call('addrbook/list-options', {
			type: '003',
			searchString: filter,
		})

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title="Nuova procedura di recesso socio"
			width="md"
		>
			<Form {...formDef}>
				<FileUpload label="Documento richiesta" name="file" tokenEndpoint="document/get-upload-token" required />
				<Input label="Protocollo AGO" name="zucchettiId" required />
				<Autocomplete label="Associato" name="addrbookItem" getOptions={getAssociatesOpts} required />
				<Checkbox label="Disattiva servizi fiscale" name="recesso_fiscale" />
				<Checkbox label="Disattiva servizi paghe" name="recesso_paghe" />
				<Checkbox label="Disattiva servizi CAA" name="recesso_caa" />
				<Checkbox label="Dismissione totale del socio" name="recesso_totale" />
			</Form>
		</Dialog>
	)
}

export const RecessoSocioComm = ({ open, handleClose, _id, handleRowRefresh }) => {
	const [ data, setData ] = useState({})

	const triggerClose = () => {
		handleRowRefresh()
		handleClose()
	}

	const formDef = {
		callSet: "workflow/recesso-socio/create-comm/set",
		callGet: "workflow/recesso-socio/create-comm/get",
		_id,
		data, setData,
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title="Preparazione comunicazione"
			width="md"
		>
			{open && (
				<Form {...formDef}>
					<InputNumber label="Residuo attuale" name="remainingAmount" disabled suffix="€" />
					<Checkbox label="Invia comunicazione" name="toBeSent" />
					<Input label="Testo della lettera" name="content" required multiline disabled={!data.toBeSent} />
					<Checkbox label="Allega scheda contabile" name="attachAccountingRecap" disabled={!data.toBeSent} />
				</Form>
			)}
		</Dialog>
	)
}
