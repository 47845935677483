import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText, SearchDateInt, SearchSelect } from '#Search'
import DataList from '#DataList'

import { faMagnifyingGlass, faFilePdf, faNote } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from '@mui/material';

export default function Covers() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ searchStipulatedInt, setSearchStipulatedInt ] = useState('')
	const [ searchZone, setSearchZone ] = useState('')
	const [ types, setTypes ] = useState([])
	const [ associations, setAssociations ] = useState([])
	const [ zones, setZones ] = useState([])

	const handlePreviewPdf = ({ _id }) => api.openFile({ tag:'contracts/get-cover', params:{ _id } })
	const handleDownloadPdf = ({ _id, _registry }) =>
		api.openFile({
			tag:'contracts/get-cover',
			params:{ _id },
			download: 'copertina-' + _registry.number + '.pdf',
		})
	const handleRefresh = () =>
		api.call('contracts/list', { searchString, searchStipulatedInt, searchZone, filterTelematics:true }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ searchString, searchStipulatedInt, searchZone ])
	useEffect(() => {
		api.call('contracts/types/options').then(setTypes)
		api.call('contracts/associations/options').then(setAssociations)
		api.call('zone/options').then(setZones)
	}, []) 

	const tableDef = [
		{ label:"Numero", content:row => (
			<>
				<Typography>{row._registry?.number}</Typography>
				{ Boolean(row.repertory) && <Typography variant="caption">Rep: {row.repertory}</Typography> }
			</>
		)},
		{ label:"Stipula", field:'stipulatedOn', type:'date' },
		{ label:"Scadenza", field:'expiresOn', type:'date' },
		{ label:"Proprietà", field:'owner' },
		{ label:"Affittuale", field:'renter' },
		{ label:"Tipologia", content:row => row?.type ? (types?.find(item => item.value===row.type)?.label || '???') : '-'},
		{ label:"Associazione", content:row => row?.association ? (associations?.find(item => item.value===row.association)?.label || '???') : '-'},
		{ label:"Zona", content:row => row?.zone ? (zones?.find(item => item.value===row.zone)?.label || '???') : '-'},
		{ label:"PTA", field:'pta', type:'boolean' },
		{ label:"ACD", field:'acd', type:'boolean' },
		{ label:"Tel.", content:row => !row.telematicRegNumber ? '-' : (
			<>
				<Typography>{row.telematicRegNumber}</Typography>
				<Typography variant="caption">{row.telematicRegDate.split('-').reverse().join('/')}</Typography>
			</>
		)},
		{ type:'icon', content: row => Boolean(row.notes) && faNote },
	]

	const getRow = _id => api.call('contracts/get', { _id })
	const defBtns = [
		{ icon:faMagnifyingGlass, label:'Visualizza copertina', onClick:handlePreviewPdf },
		{ icon:faFilePdf, label:'Scarica copertina', onClick:handleDownloadPdf },
	]
	
	return (
		<Tpl title="Stampa copertine">
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setSearchString} />
				<SearchDateInt label="Stipula" name="src-text" onChange={setSearchStipulatedInt} />
				<SearchSelect label="Zona" name="src-zone" onChange={setSearchZone} options={zones} emptyLabel="-- Tutte --" />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
		</Tpl>
	)
}
