import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'

const AddrbookItem = ({ _id }) => {
	const api = useContext(APICtx)
	const [ label, setLabel ] = useState('-')

	useEffect(() => {
		_id && api.call('addrbook/get', { _id })
			.then(result => setLabel(result?.label || '-'))
	}, [ _id ])

	return label
}
export default AddrbookItem
