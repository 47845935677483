import React from 'react';

import Form, { FileUpload } from '#Form'

export default function CampaignEdit({ _id }) {
	const formDef = {
		_id,
		callSet: _id ? "mailer/contrib2024/update" : "mailer/contrib2024/create",
		callGet: _id && "mailer/contrib2024/get",
		extraData: { type:'contrib2024' },
	}

	return (
		<Form {...formDef}>
			<FileUpload label="Allegato aggiuntivo" name="attachment" tokenEndpoint="mailer/get-upload-token" />
		</Form>
	)
}
