import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
	FormControl,
	InputLabel,
	Autocomplete,
	CircularProgress,
} from '@mui/material';

function useQuery() {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Search({ children }) {
	return (
		<Box sx={{ width:"100%", mb:1 }}>
			{children}
		</Box>
	)
}

const useQueryText = ({ name }) => {
	const query = useQuery()
	return React.useMemo(
		() => query.get(name) || '',
		[query, name]
	)
}

const useQueryDateInt = ({ name }) => {
	const query = useQuery()
	return React.useMemo(() => {
		const value = {
			from: query.get(name+'-from'),
			to: query.get(name+'-to'),
		}
		Object.keys(value).forEach(vKey => !value[vKey] && (delete value[vKey]))
		return value
	}, [query, name])
}

export const SearchDateInt = ({ name, onChange, label }) => {
	const query = useQuery()
	const navigate = useNavigate()
	const value = useQueryDateInt({ name })

	const handleChange = (sub, e) => {
		query.set(name+'-'+sub, e.target.value)
		navigate('?'+query.toString())
	}
	useEffect(() => onChange?.(value), [ value, onChange ])
	
	const labelFrom = label ? label+": dal" : "Dal"
	const labelTo = label ? label+": al" : "Al"
	return (
		<Grid container sx={{ mb:1 }}>
			<Grid item xs={6}>
				<TextField
					fullWidth
					type="date"
					value={value.from || ''}
					label={labelFrom}
					onChange={e => handleChange('from', e)}
					InputLabelProps={{ shrink:true }}
				/>
			</Grid>
			<Grid item xs={6}>
			<TextField
					fullWidth
					type="date"
					value={value.to || ''}
					label={labelTo}
					onChange={e => handleChange('to', e)}
					InputLabelProps={{ shrink:true }}
				/>
			</Grid>
		</Grid>
	)
}

export const SearchText = ({ name, onChange, label, value=null }) => {
	const [ fieldValue, setFieldValue ] = useState()

	const navigate = useNavigate()
	const query = useQuery()
	const curQuery = useQueryText({ name })

	const handleChange = newValue => {
		setFieldValue(newValue)
		onChange?.(newValue)

		query.set(name, newValue)
		navigate('?'+query.toString(), { replace:true })
	}
	useEffect(() => {
		// console.log('CH-arg', curQuery, value)
		if(curQuery !== fieldValue)
			handleChange(curQuery)
		else if(value !== null)
			handleChange(value)
	}, [ value ])

	return (
		<TextField
			fullWidth
			type="text"
			value={fieldValue || ''}
			label={label}
			onChange={e => handleChange(e?.target?.value || '')}
			sx={{ mb:1 }}
		/>
	)
}

export const SearchSelect = ({ name, onChange, label, options, defaultValue='', emptyLabel }) => {
	const query = useQuery()
	const navigate = useNavigate()
	const value = useQueryText({ name }) || defaultValue

	const handleChange = e => {
		query.set(name, e.target.value || defaultValue)
		navigate('?'+query.toString())
	}
	useEffect(() => onChange?.(value), [ value, onChange ])
	
	return (
		<FormControl fullWidth>
			<InputLabel id="search-select-label">{label}</InputLabel>
			<Select
				value={value}
				label={label}
				onChange={handleChange}
				sx={{ mb:1 }}
			>
				{ Boolean(emptyLabel) && <MenuItem key="_empty" value="">{emptyLabel}</MenuItem> }
				{ options.map((opt, optIdx) => (
					<MenuItem key={optIdx} value={opt.value}>{opt.label || opt.value}</MenuItem>
				)) }
			</Select>
		</FormControl>
	)
}

export const SearchAutoComplete = ({ name, onChange, label, defaultValue = '', emptyLabel, fetchOptions, fetchLabel }) => {
	const query = useQuery()
	const navigate = useNavigate()
	const [value, setValue] = useState(useQueryText({ name }))
	const [options, setOptions] = useState([])
	const [loading, setLoading] = useState(false)

	const handleFetch = async (searchText) => {
		setLoading(true)
		const optLabel = value && options?.find(option => option.value === value)?.label

		if(optLabel && searchText && optLabel === searchText) {
			// NO OP
		}
		else if(value && (!searchText || searchText === value))
			await fetchLabel(value).then(label => setOptions([
				{ value, label }
			]))
		else if(searchText?.length) {
			value && setValue(undefined)
			await fetchOptions(searchText)
				.then(setOptions)
				.catch(() => setOptions([]))
		}
		else
			setOptions([])
		setLoading(false)
	}

	const handleChange = (_, option) => {
		const newValue = option?.value
		query.set(name, newValue)
		navigate('?' + query.toString())
		setValue(newValue)
	}

	const isOptionEqualToValue = (option1, option2) =>
		option1?.value === option2?.value

	useEffect(() => {
		onChange?.(value)
	}, [value, onChange])

	useEffect(() => {
		value && handleFetch(value)
	}, [ value ])

	return (
		<FormControl fullWidth>
			<Autocomplete
				value={options.find(opt => opt.value === value) || null}
				onChange={handleChange}
				onInputChange={(_, inputValue) => handleFetch(inputValue)} // Fetch new options when typing
				options={options}
				getOptionLabel={(option) => option.label || option.value}
				isOptionEqualToValue={isOptionEqualToValue}
				loading={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
				sx={{ mb: 1 }}
				noOptionsText={emptyLabel}
			/>
		</FormControl>
	)
}
