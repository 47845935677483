import React from 'react'

import Form, { Input, InputDate, Select } from '#Form'

import { eventTypes } from './def'

export default function EventEdit({ _id }) {
	const formDef = {
		_id,
		callSet: _id ? "event/update" : "event/create",
		callGet: _id && "event/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Descrizione" name="description" required />
			<InputDate label="Scadenza compilazione form" name="formsExpiry" required />
			<Select label="Tipologia" name="type" options={eventTypes} emptyLabel="--- seleziona una tipologia ---" required />
		</Form>
	)
}
