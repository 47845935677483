import React, { useContext, useState, useEffect } from 'react'
import Dialog from '#Dialog'

import APICtx from '#api'
import DataList from '#DataList'
import { faMagnifyingGlass, faFilePdf } from '@fortawesome/pro-regular-svg-icons'

import { types } from './types'

const tableDef = [
	{ label:"Numero", content:row => row._registry?.number},
	{ label:"Tipologia", content:row => types.find(type => type.value === row.type)?.label || 'sconosciuto' },
	{ label:"Protocollo AGO", field:'zucchettiId' },
]

const Documents = ({ sourceId }) => {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	useEffect(() => {
		sourceId ? api.call('document/list', { filterSourceId:sourceId }).then(setIds) : setIds([])
	}, [ sourceId ])

	const defBtns = [
		{
			icon: faMagnifyingGlass,
			label: 'Visualizza',
			onClick: row => api.storage.download(row.fileJWT, true),
			show: row => Boolean(row.fileJWT),
		},
		{
			icon: faFilePdf,
			label: 'Scarica',
			onClick: row => api.storage.download(row.fileJWT, false),
			show: row => Boolean(row.fileJWT),
		},
	]

	const getRow = _id => api.call('document/get', { _id })
	return (
		<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} />
	)
}

const ListConnectedDocs = ({ sourceId, handleClose }) => {
	const open = Boolean(sourceId)
	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title="Dettaglio documenti collegati"
			width="md"
		>
			<Documents sourceId={sourceId} />
		</Dialog>
	)
}
export default ListConnectedDocs
