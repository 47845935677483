import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

const tabDef = [
	{ label:"Codice", field:'zid' },
	{ label:"Nome", field:'label' },
]

export default function AssociateTypes() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	
	const handleRefresh = () => api.call('associate-type/list').then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('associate-type/get', { _id })
	
	return (
		<Tpl title="Lista tipologie socio">
			<DataList def={tabDef} ids={ids} getRow={getRow} />
		</Tpl>
	)
}