import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SpeedDialMui from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Zoom from '@mui/material/Zoom'

import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { TplConfCtx } from './Template'

const fabStyle = {
	position: 'fixed',
	bottom: 16,
	right: 16
}

export default function SpeedDial({ actions, icon=faPlus }) {
	const tplConf = useContext(TplConfCtx)

	const dialActions = actions.map(({ tooltip, icon, linkTo, onClick }, idx) => {
		const props = {
			key: idx,
			tooltipTitle: tooltip,
			icon: <FontAwesomeIcon icon={icon} size="lg" />,
			onClick,
		}
		if(linkTo) {
			props.component = Link
			props.to = linkTo
		}
		return <SpeedDialAction {...props} />
	})

	return (
		<Zoom sx={fabStyle} in={tplConf.scrollPosition<=0}>
			<SpeedDialMui
				icon={<FontAwesomeIcon icon={icon} size="lg" />}
				ariaLabel="SpeedDial"
			>
				{ dialActions }
			</SpeedDialMui>
		</Zoom>
	)
}