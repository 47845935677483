import React from 'react';

import Form, { Input } from '#Form'

export default function TypesEdit({ _id }) {
	const formDef = {
		_id,
		callSet: "contracts/types/set",
		callGet: _id && "contracts/types/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Descrizione" name="label" />
		</Form>
	)
}
