import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tooltip } from '@mui/material';

import {
	faTrash,
	faNote,
	faPlay,
	faCheck,
	faEnvelopeCircleCheck,
	faEnvelope,
} from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import {
	workflowsDef,
	setupSymbols,
	// statesSymbols,
} from './schemas'

const Symbol = ({ tooltip, icon, icon2, color }) => {
	return (
		<Box sx={{ display:'inline', mr:1 }}>
			<Tooltip title={tooltip}>
				<span className="fa-layers fa-fw fa-lg">
					<FontAwesomeIcon icon={icon} color={color || undefined} />
					{ icon2 && <FontAwesomeIcon icon={icon2} color={color || undefined} /> }
				</span>
			</Tooltip>
		</Box>
	)
}

export const Status = ({ status, notes }) => {
	const content = []

	if(status === 'running')
		content.push(
			<Symbol icon={faPlay} tooltip="Attivo" />
		)
	if(status === 'completed')
		content.push(
			<Symbol icon={faCheck} tooltip="Completato" />
		)
	if(status === 'aborted')
		content.push(
			<Symbol icon={faTrash} tooltip="Annullato" />
		)

	if(notes)
		content.push(
			<Symbol icon={faNote} tooltip="Annotazione presente" />
		)

	return content
}

export const Config = ({ states }) =>
	setupSymbols.map(symbol => {
		const value = states?.[symbol.tag]
		const isVisible = (value || value === false)
		const label = symbol.label + ': ' + (value ? 'SI' : 'NO')
		return isVisible && (
			<Symbol
				icon={symbol.icon}
				icon2={symbol.icon2}
				tooltip={label}
				color={value ? 'blue' : 'grey'}
			/>
		)
	})

export const States = ({ type, states }) => {
	const statesSymbols = workflowsDef[type]?.states || []
	return statesSymbols.map(stateSymbol => {
		const value = states?.[stateSymbol.tag]
		const isVisible = (value || value === false)
		return isVisible && (
			<Symbol
				icon={stateSymbol.icon}
				icon2={stateSymbol.icon2}
				tooltip={stateSymbol.label}
				color={value ? 'green' : 'red'}
			/>
		)
	})
}

export const AddrbookCommChannels = ({ _id }) => {
	const api = useContext(APICtx)
	const [ contacts, setContacts ] = useState('-')

	useEffect(() => {
		_id && api.call('addrbook/get', { _id })
			.then(result => setContacts(result?.contacts || null))
	}, [ _id ])

	return [
		<Symbol
			icon={faEnvelope}
			tooltip={"EMail " + (contacts?.email?.length ? 'disponibile' : 'non disponibile')}
			color={contacts ? (contacts?.email?.length ? 'green' : 'red') : 'grey'}
		/>,
		<Symbol
			icon={faEnvelopeCircleCheck}
			tooltip={"PEC " + (contacts?.pec ? 'disponibile' : 'non disponibile')}
			color={contacts ? (contacts?.pec ? 'green' : 'red') : 'grey'}
		/>,
	]
}