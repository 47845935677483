import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText } from '#Search'
import DataList from '#DataList'
import Dialog from '#Dialog'
import AssociationsEdit from './AssociationsEdit'
import Fab from '#Fab'

import { faPlus, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons'

const EditDialog = ({ edit, handleEditClose }) => (
	<Dialog
		handleClose={handleEditClose}
		open={edit!==null}
		title={edit?._id ? "Modifica associazione" : "Crea nuova associazione"}
		width="md"
	>
		{ edit!==null && <AssociationsEdit _id={edit._id} /> }
	</Dialog>
)

const tableDef = [
	{ label:"Denominazione", field:'label' },
]

export default function ContractAssociationsList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ edit, setEdit ] = useState(null)
	
	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare l\'associazione "' + label + '"?',
			onConfirm: () => api.call('contracts/associations/del', { _id }).then(handleRefresh),
		})
	}
	const handleEditClose = async () => {
		await edit.handleRowRefresh()
		setEdit(null)
		return handleRefresh()
	}
	const handleRefresh = () =>
		api.call('contracts/associations/list', { searchString }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ searchString ])

	const getRow = _id => api.call('contracts/associations/get', { _id })
	const defBtns = [
		{ icon:faEdit, label:'Modifica', onClick:setEdit },
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]
	
	return (
		<Tpl title="Associazioni presenti nei contratti">
			<EditDialog edit={edit} handleEditClose={handleEditClose} />
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setSearchString} />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			<Fab label="Crea un'associazione" icon={faPlus} onClick={() => setEdit({})} />
		</Tpl>
	)
}
