import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText } from '#Search'
import DataList from '#DataList'
import AddrbookItem from '../addrbook/AddrbookItem';
import { types } from './types'

import { faMagnifyingGlass, faFilePdf } from '@fortawesome/pro-regular-svg-icons'

const tableDef = [
	{ label:"Numero", content:row => row._registry?.number},
	{ label:"Tipologia", content:row => types.find(type => type.value === row.type)?.label || 'sconosciuto' },
	{ label:"Anagrafica", content:row => <AddrbookItem _id={row.addrbookItem} />},
	{ label:"Protocollo AGO", field:'zucchettiId' },
]

export default function Documents() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ filterString, setFilterString ] = useState('')

	const handleRefresh = () =>
		api.call('document/list', { filterString }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ filterString ])

	const getRow = _id => api.call('document/get', { _id })
	const defBtns = [
		{
			icon: faMagnifyingGlass,
			label: 'Visualizza',
			onClick: row => api.storage.download(row.fileJWT, true),
			show: row => Boolean(row.fileJWT),
		},
		{
			icon: faFilePdf,
			label: 'Scarica',
			onClick: row => api.storage.download(row.fileJWT, false),
			show: row => Boolean(row.fileJWT),
		},
	]

	return (
		<Tpl title="Archivio documenti">
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setFilterString} />
				{/* <SearchSelect label="Tipologia" name="src-type" onChange={setSearchType} options={types} emptyLabel="-- Tutte le tipologie --" /> */}
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
		</Tpl>
	)
}
