import React from 'react';

import Form, { Input } from '#Form'

export default function AssociationsEdit({ _id }) {
	const formDef = {
		_id,
		callSet: "contracts/associations/set",
		callGet: _id && "contracts/associations/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Denominazione" name="label" />
		</Form>
	)
}
