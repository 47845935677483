export const types = [
	{ value:'richiesta-recesso-socio', label:'Richiesta recesso socio' },
	{ value:'recesso-lettera-pagamenti', label:'Lettera per mancati pagamenti' },
	
	{ value:'scheda-contabile', label:'Scheda contabile' },
	{ value:'scheda-contabile-upa', label:'Scheda contabile UPA' },
	{ value:'scheda-contabile-srl', label:'Scheda contabile SRL' },

	{ value:'lettera-messainmora-upa', label:'Lettera messa in mora UPA' },
	{ value:'lettera-diffida-upa', label:'Lettera diffida UPA' },
	{ value:'lettera-sospensione-upa', label:'Lettera preavviso sospensione UPA' },
	{ value:'lettera-messainmora-srl', label:'Lettera messa in mora SRL' },
	{ value:'lettera-diffida-srl', label:'Lettera diffida SRL' },
	{ value:'lettera-sospensione-srl', label:'Lettera preavviso sospensione SRL' },

	{ value:'espulsione-lettera', label:'Lettera di espulsione' },

	{ value:'mailer-contabile-letter', label:'Invio scheda contabile: lettera' },
	{ value:'mailer-contabile-summary', label:'Invio scheda contabile: scheda' },
]