import React from 'react';

import Form, { Input, Checkbox, InputDate, FileUpload, Select } from '#Form'

export default function DetailsEdit({ _id, types, associations, zones }) {
	const formDef = {
		_id,
		callSet: "contracts/set",
		callGet: _id && "contracts/get",
	}

	return (
		<Form {...formDef}>
			<Select label="Tipo contratto" name="type" options={types} emptyLabel="--- Seleziona una tipologia ---" />
			<InputDate label="Stipula" name="stipulatedOn" />
			<InputDate label="Scadenza" name="expiresOn" />
			<Input label="Proprietà" name="owner" required />
			<Input label="Affittuale" name="renter" required />
			<Select label="Associazione" name="association" options={associations} emptyLabel="--- Seleziona un'associazione ---" />
			<Select label="Zona" name="zone" options={zones} emptyLabel="--- Seleziona una zona ---" />
			<Checkbox label="PTA" name="pta" />
			<Checkbox label="ACD" name="acd" />
			<Input label="Numero registrazione telematica" name="telematicRegNumber" />
			<InputDate label="Data registrazione telematica" name="telematicRegDate" />
			<Input label="Note" name="notes" multiline />
			<FileUpload label="Scansione" name="file" tokenEndpoint="contracts/get-upload-token" />
		</Form>
	)
}
