import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

import { faMagnifyingGlassDollar, faFilePdf, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Anno", field:'year' },
	{ label:"Totale", field:'totalTotal', type:'money' },
	{ label:"Residuo complessivo", field:'remainingTotal', type:'money' },
	{ label:"Residuo UPA", field:'remainingUPA', type:'money' },
	{ label:"Residuo SRL", field:'remainingSRL', type:'money' },
]

export default function AccountingYearsSummary() {
	const api = useContext(APICtx)
	const [ anagr, setAnagr ] = useState({})
	const [ years, setYears ] = useState([])
	const { customer } = useParams()
	
	const handlePreviewPdf = (filter) =>
		api.openFile({
			tag: 'accounting/customer-summary/pdf',
			params: { customer, ...filter },
		})
	const handleDownloadPdf = (filter) =>
		api.openFile({
			tag: 'accounting/customer-summary/pdf',
			params: { customer, ...filter },
			download: 'schcontabile-' + (anagr.vat || anagr.taxCode) + '.pdf',
		})
	const handleRefresh = () => {
		api.call('addrbook/get', { _id:customer }).then(setAnagr)
		api.call('accounting/list-years', { customer }).then(setYears)
	}
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = year => api.call('accounting/year-summary', { customer, year })
	const defBtns = [
		{ icon:faMagnifyingGlassDollar, label:'Dettaglio pagamenti', linkTo: row => '/accounting/details/'+customer+'/'+row.year }
	]
	const tableActions = [
		{ icon:faFilePdf, label:'S.C. UPA', onClick:() => handleDownloadPdf({ upa:true, srl:false }) },
		{ icon:faMagnifyingGlass, label:'S.C. UPA', onClick:() => handlePreviewPdf({ upa:true, srl:false }) },
		{ icon:faFilePdf, label:'S.C. SRL', onClick:() => handleDownloadPdf({ upa:false, srl:true }) },
		{ icon:faMagnifyingGlass, label:'S.C. SRL', onClick:() => handlePreviewPdf({ upa:false, srl:true }) },
		{ icon:faFilePdf, label:'S.C. Unica', onClick:() => handleDownloadPdf({ upa:true, srl:true }) },
		{ icon:faMagnifyingGlass, label:'S.C. Unica', onClick:() => handlePreviewPdf({ upa:true, srl:true }) },
	]
	
	const title = 'Riepilogo - ' + (anagr?.label || '???')
	return (
		<Tpl title={title} backTo="/addrbook/customers">
			<DataList def={tabDef} rowActions={defBtns} ids={years} getRow={getRow} listActions={tableActions} />
		</Tpl>
	)
}
