import React, { useState, useContext } from 'react'
import { Typography } from '@mui/material';

import Form, { Checkbox, Autocomplete, Input, FileUpload, InputNumber } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

export const CommMorosiCreate = ({ open, handleClose }) => {
	const api = useContext(APICtx)

	const formDef = {
		callSet: "workflow/comunicazione-morosi/create",
	}

	const getAssociatesOpts = async (filter) =>
		api.call('addrbook/list-options', {
			type: '003',
			searchString: filter,
		})

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title="Nuova procedura di comunicazione ai soci morosi"
			width="md"
		>
			<Form {...formDef}>
				<Autocomplete label="Associato" name="addrbookItem" getOptions={getAssociatesOpts} required />
			</Form>
		</Dialog>
	)
}

export const MorosiMessaInMora = ({ open, handleClose, _id, handleRowRefresh }) => {
	const [ data, setData ] = useState({})

	const triggerClose = () => {
		handleRowRefresh()
		handleClose()
	}

	const formDef = {
		callSet: "workflow/comunicazione-morosi/create-messainmora/set",
		callGet: "workflow/comunicazione-morosi/create-messainmora/get",
		_id,
		data, setData,
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title="Preparazione messa in mora"
			width="lg"
		>
			{open && (
				<Form {...formDef}>
					<Typography variant="h6" sx={{ mb:2 }}>Unione:</Typography>
					<InputNumber label="Residuo attuale" name="upaRemaining" disabled suffix="€" />
					<Checkbox label="Invia comunicazione" name="upaToBeSent" />
					<Input label="Testo della lettera" name="upaContent" required multiline disabled={!data?.upaToBeSent} />
					<Checkbox label="Allega scheda contabile" name="upaAttachAccounting" disabled={!data?.upaToBeSent} />

					<Typography variant="h6" sx={{ mb:2 }}>SRL:</Typography>
					<InputNumber label="Residuo attuale" name="srlRemaining" disabled suffix="€" />
					<Checkbox label="Invia comunicazione" name="srlToBeSent" />
					<Input label="Testo della lettera" name="srlContent" required multiline disabled={!data?.srlToBeSent} />
					<Checkbox label="Allega scheda contabile" name="srlAttachAccounting" disabled={!data?.srlToBeSent} />
				</Form>
			)}
		</Dialog>
	)
}

export const MorosiDiffida = ({ open, handleClose, _id, handleRowRefresh }) => {
	const [ data, setData ] = useState({})

	const triggerClose = () => {
		handleRowRefresh()
		handleClose()
	}

	const formDef = {
		callSet: "workflow/comunicazione-morosi/create-diffida/set",
		callGet: "workflow/comunicazione-morosi/create-diffida/get",
		_id,
		data, setData,
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title="Preparazione diffida"
			width="lg"
		>
			{open && (
				<Form {...formDef}>
					<Typography variant="h6" sx={{ mb:2 }}>Unione:</Typography>
					<InputNumber label="Residuo attuale" name="upaRemaining" disabled suffix="€" />
					<Checkbox label="Invia comunicazione" name="upaToBeSent" />
					<Input label="Testo della lettera" name="upaContent" required multiline disabled={!data?.upaToBeSent} />
					<Checkbox label="Allega scheda contabile" name="upaAttachAccounting" disabled={!data?.upaToBeSent} />

					<Typography variant="h6" sx={{ mb:2 }}>SRL:</Typography>
					<InputNumber label="Residuo attuale" name="srlRemaining" disabled suffix="€" />
					<Checkbox label="Invia comunicazione" name="srlToBeSent" />
					<Input label="Testo della lettera" name="srlContent" required multiline disabled={!data?.srlToBeSent} />
					<Checkbox label="Allega scheda contabile" name="srlAttachAccounting" disabled={!data?.srlToBeSent} />
				</Form>
			)}
		</Dialog>
	)
}

export const MorosiSospensione = ({ open, handleClose, _id, handleRowRefresh }) => {
	const [ data, setData ] = useState({})

	const triggerClose = () => {
		handleRowRefresh()
		handleClose()
	}

	const formDef = {
		callSet: "workflow/comunicazione-morosi/create-sospensione/set",
		callGet: "workflow/comunicazione-morosi/create-sospensione/get",
		_id,
		data, setData,
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title="Preparazione preavviso sospensione"
			width="lg"
		>
			{open && (
				<Form {...formDef}>
					<Typography variant="h6" sx={{ mb:2 }}>Unione:</Typography>
					<InputNumber label="Residuo attuale" name="upaRemaining" disabled suffix="€" />
					<Checkbox label="Invia comunicazione" name="upaToBeSent" />
					<Input label="Testo della lettera" name="upaContent" required multiline disabled={!data?.upaToBeSent} />
					<Checkbox label="Allega scheda contabile" name="upaAttachAccounting" disabled={!data?.upaToBeSent} />

					<Typography variant="h6" sx={{ mb:2 }}>SRL:</Typography>
					<InputNumber label="Residuo attuale" name="srlRemaining" disabled suffix="€" />
					<Checkbox label="Invia comunicazione" name="srlToBeSent" />
					<Input label="Testo della lettera" name="srlContent" required multiline disabled={!data?.srlToBeSent} />
					<Checkbox label="Allega scheda contabile" name="srlAttachAccounting" disabled={!data?.srlToBeSent} />
				</Form>
			)}
		</Dialog>
	)
}
