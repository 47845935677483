import React, { useContext } from 'react'
import Form, { Input } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id }) => {
	const api = useContext(APICtx)

	const aclRW = api.auth.chk('workflows-admin')

	const formDef = {
		_id,
		callSet: aclRW && "workflow/update",
		callGet: _id && "workflow/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Note" name="notes" multiline disabled={!aclRW} />
		</Form>
	)
}

const UpdateWorkflow = ({ _registry, _id, handleClose, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRowRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={_id}
			title={(_registry?.number || '') + " - Dati flusso"}
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default UpdateWorkflow
