import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Form, { Input, Select } from '#Form'

import { participantStates, referenceOptions, attendOptions, menuOptions } from './def'

export default function EventParticipantEdit({ _id }) {
	const { eventId } = useParams()
	// const { _id } = useParams()
	// const [ data, setData ] = useState({})

	const formDef = {
		_id,
		callSet: _id ? "event/participant/update" : "event/participant/create",
		callGet: _id && "event/participant/get",
		// data, setData,
		extraData: { eventId },
	}

	return (
		<Form {...formDef}>
			<Input label="Azienda" name="businessName" required />
			<Input label="Cod. Fisc." name="taxCode" required />
			<Input label="Partecipante" name="participant" required />
			<Input label="Telefono" name="phone" required />
			<Input label="Email" name="email" required />
			<Select label="Riferimento" name="reference" options={referenceOptions} emptyLabel="--- seleziona un riferimento ---" required />
			<Select label="Partecipazione" name="attend" options={attendOptions} emptyLabel="--- seleziona una voce ---" required />
			<Select label="Menu" name="menu" options={menuOptions} emptyLabel="--- seleziona una voce ---" />
			<Select label="Stato" name="status" options={participantStates} emptyLabel="--- seleziona uno stato ---" required />
			<Input label="Posto assegnato" name="seat" />
		</Form>
	)
}
