import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText, SearchSelect } from '#Search'
import DataList from '#DataList'
import Dialog from '#Dialog'
import DetailsEdit from './DetailsEdit'

import { faMoneyBillTrendUp, faQuestion, faMoneyBill, faGavel, faShopSlash, faShopLock, faHandshake, faLock, faMoneyBillTransfer, faHandsHoldingDollar, faEdit, faNote } from '@fortawesome/pro-regular-svg-icons'
import { Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const types = [
	{ tag:'customers', label:'Clienti', dbType:'003' },
]

const latePaymentsMap = {
	'00001': { icon:faMoneyBill, color:'orange', tooltip:'Messo in mora' },
	'00002': { icon:faLock, color:'red', tooltip:'Sospensione servizi' },
	'00003': { icon:faLock, color:'orange', tooltip:'Preavviso sospensione Servizi' },
	'00004': { icon:faHandsHoldingDollar, color:'blue', tooltip:'Recupero Crediti' },
	'00005': { icon:faShopSlash, color:'gray', tooltip:'Cessato non più Operativo' },
	'00006': { icon:faShopLock, color:'black', tooltip:'Espulso Art. 7 bis' },
	'00007': { icon:faGavel, color:'cornflowerblue', tooltip:'Tribunale Fallimenti e Concordati' },
	'00008': { icon:faHandshake, color:'green', tooltip:'Convenzione' },
	'00009': { icon:faMoneyBillTrendUp, color:'green', tooltip:'Piano di rientro' },
	'00010': { icon:faMoneyBillTransfer, color:'green', tooltip:'Piano di Rientro con DCE' },
	'00011': { icon:faGavel, color:'blue', tooltip:'Al Legale' },
	'99999': { icon:faMoneyBill, color:'green', tooltip:'Nessuna morosità' },
	'unknown': { icon:faQuestion, color:'orange', tooltip:'Stato sconosciuto' },
}

const EditDetails = ({ detailsEdit, handleDetailsClose }) => (
	<Dialog
		handleClose={handleDetailsClose}
		open={Boolean(detailsEdit?._id)}
		title={"Dettagli cliente: " + (detailsEdit?.label || '')}
		width="md"
	>
		{ Boolean(detailsEdit?._id) && <DetailsEdit _id={detailsEdit._id} /> }
	</Dialog>
)

const mkAddress = row =>
	(row.address?.addressType ? row.address.addressType+' ' : '') +
	row.address?.address + (row.address?.number ? ' '+row.address.number : '') + ', ' +
	row.address?.zip + ' ' + row.address?.municipality

const useTableDef = () => {
	// const api = useContext(APICtx)
	// const [ zones, setZones ] = useState([])

	// useEffect(() => {
	// 	api.call('zone/options').then(setZones)
	// }, [])

	return useMemo(() => ([
		{ label:"Denominazione", content:row => (
			<>
				<Typography>{row.label}</Typography>
				{ Boolean(row.address) &&
					<Typography variant="caption">
						{mkAddress(row)}
					</Typography>
				}
			</>
		)},
		{ label:"Delega INPS", field:'inpsDelegation', type:'boolean', falseIcon:null },
		{ label:"Morosità", cellAlign:'center', content:row => {
			if(row.latePaymentStateZID) {
				const stateDef = latePaymentsMap?.[row.latePaymentStateZID] || latePaymentsMap['unknown']
				return (
					<Tooltip title={stateDef.tooltip}>
						<FontAwesomeIcon icon={stateDef.icon} color={stateDef.color} size="xl" />
					</Tooltip>
				)
			}
		}},
		{ label:"P.IVA", field:'vat' },
		{ label:"C.Fisc", field:'taxCode' },
		{ label:"Residuo", field:'remainingAmount', type:'money' },
		{ type:'icon', content: row => Boolean(row.notes) && faNote },
		// { label:"Zona", content:row => (row?.extras?.zoneZID && zones.find(z => z.zid === row.extras.zoneZID)?.label) || '' },
	]), [])
}

const useCsvDef = () => {
	const api = useContext(APICtx)
	const [ zones, setZones ] = useState([])

	useEffect(() => {
		api.call('zone/options').then(setZones)
	}, [])

	return useMemo(() => ([
		{ label:"Codice cliente", field:'zid' },
		{ label:"Denominazione", field:'label' },
		{ label:"Tipo indirizzo", content:row => row?.address?.addressType },
		{ label:"Indirizzo", content:row => row?.address?.address },
		{ label:"Numero", content:row => row?.address?.number },
		{ label:"CAP", content:row => row?.address?.zip },
		{ label:"Comune", content:row => row?.address?.municipality },
		{ label:"Delega INPS", content:row => row.inpsDelegation ? '1' : '' },
		{ label:"Morosità", cellAlign:'center', content:row => {
			const stateDef = latePaymentsMap?.[row.latePaymentStateZID] || latePaymentsMap['unknown']
			return stateDef.tooltip
		}},
		{ label:"P.IVA", field:'vat' },
		{ label:"C.Fisc", field:'taxCode' },
		{ label:"Telefono", content:row => row?.contacts?.phone?.length ? row.contacts.phone.join('\n') : '' },
		{ label:"Cellulare", content:row => row?.contacts?.mobile?.length ? row.contacts.mobile.join('\n') : '' },
		{ label:"Email", content:row => row?.contacts?.email?.length ? row.contacts.email.join('\n') : '' },
		{ label:"PEC", content:row => row?.contacts?.pec || '' },
		{ label:"Zona", content:row => (row?.extras?.zoneZID && zones.find(z => z.zid === row.extras.zoneZID)?.label) || '' },
		{ label:"Note", field:'notes' },
	]), [ zones ])
}

export default function AddrbookList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ searchLatePaymentState, setSearchLatePaymentState ] = useState('')
	const [ searchZone, setSearchZone ] = useState('')
	const [ latePaymentsStates, setLatePaymentsStates ] = useState([])
	const { type } = useParams()
	const [ detailsEdit, setDetailsEdit ] = useState(null)
	const [ zones, setZones ] = useState([])
	const csvName = 'anagrafiche_clienti'
	const csvDef = useCsvDef()
	const tableDef = useTableDef()

	const typeDef = types.filter(item => item.tag===type)[0] || {}

	const handleDetailsClose = async () => {
		await detailsEdit.handleRowRefresh()
		setDetailsEdit(null)
		return handleRefresh()
	}
	const handleRefresh = () =>
		api.call('addrbook/list', { type:typeDef.dbType, searchString, searchLatePaymentState, searchZone }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ searchString, searchLatePaymentState, searchZone ])
	useEffect(() => {
		api.call('late-payments-state/options').then(setLatePaymentsStates)
		api.call('zone/options').then(setZones)
	}, [])

	const getRow = _id => api.call('addrbook/get', { _id })
	const defBtns = [
		{ icon:faEdit, label:'Modifica dettagli', onClick:setDetailsEdit },
		{ icon:faMoneyBillTrendUp, label:'Schede contabili', linkTo: row => '/accounting/summary/'+row._id },
	]

	const title = 'Anagrafiche - ' + typeDef.label
	return (
		<Tpl title={title}>
			<EditDetails detailsEdit={detailsEdit} handleDetailsClose={handleDetailsClose} />
			<Search>
				<SearchText label="Cerca denominazione / P.IVA / C.Fisc." name="src-text" onChange={setSearchString} />
				<SearchSelect label="Stato morosità" name="late-payment-state" onChange={setSearchLatePaymentState} options={latePaymentsStates} emptyLabel="-- Tutti --" />
				<SearchSelect label="Zona" name="src-zone" onChange={setSearchZone} options={zones} emptyLabel="-- Tutte --" />
			</Search>
			<DataList def={tableDef} csvDef={csvDef} csvName={csvName} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
		</Tpl>
	)
}
