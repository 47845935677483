import React, { useState, useContext } from 'react'
import Form, { Checkbox, Autocomplete, Input, FileUpload, InputNumber } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

export const EspulsioneCreate = ({ open, handleClose }) => {
	const api = useContext(APICtx)

	const formDef = {
		callSet: "workflow/espulsione/create",
	}

	const getAssociatesOpts = async (filter) =>
		api.call('addrbook/list-options', {
			type: '003',
			searchString: filter,
		})

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title="Nuova procedura di espulsione"
			width="md"
		>
			<Form {...formDef}>
				<Autocomplete label="Associato" name="addrbookItem" getOptions={getAssociatesOpts} required />
			</Form>
		</Dialog>
	)
}

export const EspulsioneComm = ({ open, handleClose, _id, handleRowRefresh }) => {
	// const [ data, setData ] = useState({})

	const triggerClose = () => {
		handleRowRefresh()
		handleClose()
	}

	const formDef = {
		callSet: "workflow/espulsione/create-comm/set",
		callGet: "workflow/espulsione/create-comm/get",
		_id,
		// data, setData,
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title="Preparazione comunicazione"
			width="md"
		>
			{open && (
				<Form {...formDef}>
					<Input label="Testo della lettera" name="content" required multiline />
				</Form>
			)}
		</Dialog>
	)
}
