import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

const tabDef = [
	{ label:"Codice", field:'code' },
	{ label:"Nome", field:'label' },
]

export default function ZoneList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	
	const handleRefresh = () => api.call('zone/list').then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('zone/get', { _id })
	
	return (
		<Tpl title="Lista zone">
			<DataList def={tabDef} ids={ids} getRow={getRow} />
		</Tpl>
	)
}