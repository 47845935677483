import React from 'react';
import QRCode from "react-qr-code";
import Dialog from '#Dialog'
import { Typography } from '@mui/material';

export const QrDetails = ({ url, label, level, handleClose }) => {
	return (
		<Dialog
			handleClose={handleClose}
			open={Boolean(url)}
			title={label}
			width="md"
		>
			{ url && (
				<QRCode
					size={512}
					value={url}
					style={{ margin: "0 auto" }}
					level={level || 'L'}
				/>
			)}
			<Typography sx={{ textAlign:'center', m:2 }} variant='h6'>
				<a target='_blank' rel="noreferrer" href={url}>Apri il link</a>
			</Typography>
		</Dialog>
	)
}
