import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Search, { SearchText, SearchSelect } from '#Search'

import { faDownload, faLock, faMagnifyingGlass, faPrint, faTrash, faUnlock, faBooks } from '@fortawesome/pro-regular-svg-icons'
import {
	campaigns,
	rcptStatuses,
} from './def'
import ListConnectedDocs from '../documents/ListConnectedDocs';

const tabDef = [
	{ label:"P.IVA / Cod. Fisc.", field:'rcptCode' },
	{ label:"Nominativo cliente", field:'customerLabel' },
	{ label:"Email", field:'rcptEmail' },
	{ label:"PEC", field:'rcptPec' },
	{
		label: "Stato",
		content: row => rcptStatuses.find(item => item.value === row.status)?.icon,
		tooltip: row => rcptStatuses.find(item => item.value === row.status)?.label,
		type: 'icon',
	},
]

const useCsvDef = () => {
	const api = useContext(APICtx)
	const [ zones, setZones ] = useState([])

	useEffect(() => {
		api.call('zone/options').then(setZones)
	}, [])

	return useMemo(() => ([
		{ label:"P.IVA / Cod. Fisc.", field:'rcptCode' },
		{ label:"Nominativo cliente", field:'customerLabel' },
		{ label:"Email", field:'rcptEmail' },
		{ label:"PEC", field:'rcptPec' },
		{ label: "Stato", content: row => rcptStatuses.find(item => item.value === row.status)?.label },
		{ label:"Zona", content:row => (row?.anagr?.zone && zones.find(z => z.value === row.anagr?.zone)?.label) || '' },
	]), [ zones ])
}

export default function RcptsList() {
	const api = useContext(APICtx)
	const { _id } = useParams()
	const [ ids, setIds ] = useState([])
	const [ campaign, setCampaign ] = useState({})
	const [ searchString, setSearchString ] = useState('')
	const [ rcptStatus, setRcptStatus ] = useState('')
	const [ connectedDocsSource, setConnectedDocsSource ] = useState()
	const csvName = 'destinatari_mailinglist'
	const csvDef = useCsvDef()

	const handleRefresh = () => api.call('mailer/rcpt/list', { _id, searchString, rcptStatus }).then(setIds)
	const handleEnable = ({ _id }) => api.call('mailer/rcpt/enable', { _id })
	const handleDisable = ({ _id }) => api.call('mailer/rcpt/disable', { _id })
	const handleDelete = ({ _id, rcptCode }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il destinatario "' + rcptCode + '"?',
			onConfirm: () => api.call('mailer/rcpt/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		api.call('mailer/get', { _id }).then(setCampaign)
	}, [])
	useEffect(() => {
		handleRefresh()
	}, [ searchString, rcptStatus ])

	const getRow = _id => api.call('mailer/rcpt/get', { _id })

	const defBtns = [
		{
			icon: faBooks,
			label: 'Documenti',
			onClick: row => setConnectedDocsSource(row._id),
		},
		{
			icon: faMagnifyingGlass,
			label: 'Anteprima allegato',
			onClick: row => api.storage.download(row.pdfJwt, true),
			show: row => Boolean(row.pdfJwt),
		},
		{
			icon: faDownload,
			label: 'Scarica allegato',
			onClick: row => api.storage.download(row.pdfJwt, false),
			show: row => Boolean(row.pdfJwt),
		},
		{
			icon: faLock,
			label: 'Blocca destinatario',
			onClick: handleDisable,
			show: row => rcptStatuses.find(status => status.value === row.status)?.allowLock,
		},
		{
			icon: faUnlock,
			label: 'Riattiva destinatario',
			onClick: handleEnable,
			show: row => row.status === 'disabled',
		},
	]
	if(api.auth.chk('mailer-admin'))
		defBtns.push({
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		})

	const defActions = []
	if(campaign?.paperPdfJwt)
		defActions.push({
			icon: faPrint,
			label: 'Scarica cartaceo',
			onClick: () => api.storage.download(campaign?.paperPdfJwt, false),
		})

	const title = "Lista destinatari - " +
		(campaigns.find(def => def.tag === campaign?.type)?.label || '?') +
		" - #" +
		campaign?._registry?.number +
		(campaign?.paper?.reqBuild ? ' - Cartaceo in preparazione' : '')
	return (
		<Tpl title={title}>
			<ListConnectedDocs sourceId={connectedDocsSource} handleClose={() => setConnectedDocsSource(null)} />
			<Search>
				<SearchText label="Cerca denominazione / P.IVA / C.Fisc." name="src-text" onChange={setSearchString} />
				<SearchSelect label="Stato destinatario" name="rcpt-status" onChange={setRcptStatus} options={rcptStatuses} emptyLabel="-- Tutti --" />
			</Search>
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} listActions={defActions} csvDef={csvDef} csvName={csvName} pageSize={30} />
		</Tpl>
	)
}
