import React, { useState } from 'react'
import { useParams } from "react-router-dom"
import { Paper, Typography } from '@mui/material';

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Radio } from '#Form'

import consultantsOpts from './consultants.json'

const yesnoOpts = [
	{ value:'yes', label:'Si' },
	{ value:'no', label:'No' },
]
const truefalseOpts = [
	{ value:'yes', label:'Affermazione vera' },
	{ value:'no', label:'Affermazione falsa' },
]
const activityOpts = [
	{ value:'agricola-primaria', label:'Produzione agricola primaria' },
	{ value:'agromeccanica', label:'Agromeccanica' },
	{ value:'ittica', label:'Ittica' },
]

export default function IsmeaEdit() {
	const { _id } = useParams()
	const [ data, setData ] = useState({})

	const formDef = {
		_id,
		callSet: _id ? "ismea/update" : "ismea/create",
		callGet: _id && "ismea/get/full",
		data, setData,
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' bando'
	const backTo = '/ismea/list'
	return (
		<Tpl title={title} backTo={backTo}>
			<Form {...formDef}>
				<Paper sx={{ p:1, mb:1 }}>
					<Input label="Ragione sociale" name="companyName" required />
					<Input label="Legale rappresentante (firmatario)" name="companyOwner" required />
					<Input label="Codice fiscale" name="taxCode" required />
					<Input label="Partita IVA" name="vat" required />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>Sede Legale</Typography>
					<Input label="Via/Numero" name="office.address" />
					<Input label="CAP" name="office.zip" />
					<Input label="Città" name="office.city" />
				</Paper>
				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>Sede Operativa</Typography>
					<Input label="Via/Numero" name="ophq.address" />
					<Input label="CAP" name="ophq.zip" />
					<Input label="Città" name="ophq.city" />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>Contatti</Typography>
					<Input label="Telefono" name="contacts.phone" />
					<Input label="Fax" name="contacts.fax" />
					<Input label="Email" name="contacts.email" />
					<Input label="PEC" name="contacts.pec" />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Radio
						label="Matricola INPS"
						name="inps.isAvailable"
						options={yesnoOpts}
						required
					/>
					{ data?.inps?.isAvailable === 'yes' && (
						<>
							<Input label="INPS - Nr. Matricola" name="inps.register" required />
							<Input label="INPS - Sede" name="inps.site" required />
						</>
					)}
					{ data?.inps?.isAvailable === 'no' && (
						<Input label="INPS - Motivazione assenza" name="inps.absenceReason" required />
					)}
					<Radio
						label="Codice INAIL"
						name="inail.isAvailable"
						options={yesnoOpts}
						required
					/>
					{ data?.inail?.isAvailable === 'yes' && (
						<>
							<Input label="INAIL - Nr. Matricola" name="inail.register" required />
							<Input label="INAIL - Sede" name="inail.site" required />
						</>
					)}
					<Input label="Codice ATECO" name="ateco" />
					<Input label="Codice Univoco Fatturazione" name="sdiCode" />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>Referente da contattare per comunicazioni relative al bando</Typography>
					<Input label="Nome e Cognome" name="referent.name" />
					<Input label="Telefono" name="referent.phone" />
					<Input label="Email" name="referent.email" />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>Attività esercitata</Typography>
					<Input label="Breve descrizione" name="companyDescription" multiline />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>Informazioni bancarie da utilizzare per il bando</Typography>
					<Typography gutterBottom variant="caption">Il conto corrente deve essere intestato al beneficiario della domanda</Typography>
					<Input label="IBAN beneficiario" name="beneficiaryIBAN" />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>Dati contabili</Typography>
					<Input label="Servizio di contabilità gestito da" name="accounting.managedBy" />
					<Input label="Referente dati contabili - Nome e cognome" name="accounting.referent.name" />
					<Input label="Referente dati contabili - Telefono" name="accounting.referent.phone" />
					<Input label="Referente dati contabili - Email" name="accounting.referent.email" />
					<Input
						label="La società ha partecipazioni (anche per mezzo di persone fisiche)? indicare ragione sociale e percentuale di partecipazione"
						name="accounting.shareholdings"
						multiline
					/>
					<Input label="Numero ULA - 2022" name="accounting.ula2022" />
					<Input label="Numero ULA - 2021" name="accounting.ula2021" />
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Typography gutterBottom>INFORMAZIONI</Typography>
					<Radio
						label="L'impresa risulta attiva ed iscritta al Registro delle imprese?"
						name="isRegistered"
						options={yesnoOpts}
						required
					/>
					<Radio
						label="L'impresa è in regola con il versamento del diritto camerale annuale?"
						name="isCameralePaymentsOk"
						options={yesnoOpts}
						required
					/>
					<Radio
						label="L'impresa è sottoposta a procedura concorsuale, si trova in stato di fallimento, di liquidazione anche volontaria, di amministrazione controllata, di concordato preventivo o in qualsiasi altra situazione equivalente ai sensi della normativa vigente?"
						name="isBankrupted"
						options={yesnoOpts}
						required
					/>
					<Radio
						label="L'impresa è in regola con gli adempimenti fiscali?"
						name="isFiscallyOk"
						options={yesnoOpts}
						required
					/>
					<Radio
						label="L'impresa ha ricevuto o richiesto, per le spese oggetto della pratica, altro contributo pubblico?"
						name="isOtherPublicContributionRequested"
						options={yesnoOpts}
						required
					/>
					<Radio
						label="L'impresa è destinataria di sanzioni interdittive o ha ricevuto e successivamente non rimborsato o depositato in un conto bloccato aiuti sui quali pende un ordine di recupero?"
						name="isUnderSanctions"
						options={yesnoOpts}
						required
					/>
					<Radio
						label="Infine si dichiara che, compatibilmente con quanto richiesto dal bando, l'impresa non risulta in stato di difficoltà"
						name="isNotUnderDifficulties"
						options={truefalseOpts}
						required
					/>
					<Radio
						label="L'impresa esercita l'attività di seguito precisata"
						name="companyActivity"
						options={activityOpts}
						required
					/>
					<Radio
						label="L'impresa chiede che, in caso di esito favorevole del bando, ISMEA proceda direttamente al pagamento del concessionario?"
						name="dealer.paidDirectly"
						options={yesnoOpts}
						required
					/>
					{ data?.dealer?.paidDirectly === 'yes' && (
						<>
							<Input label="Ragione sociale" name="dealer.name" required />
							<Input label="Sede legale - Via/Numero" name="dealer.office.address" />
							<Input label="Sede legale - CAP" name="dealer.office.zip" />
							<Input label="Sede legale - Città" name="dealer.office.city" />
							<Input label="Codice fiscale" name="dealer.taxCode" />
							<Input label="Partita IVA" name="dealer.vat" />
							<Input label="Codice IBAN" name="dealer.iban" required />
						</>
					)}
					<Radio
						label="Indicare se si intende richiedere anche la garanzia bancaria emessa da ISMEA a favore del richiedente"
						name="isWarrantyRequested"
						options={yesnoOpts}
						required
					/>
					<Radio
						label="Il richiedente è già iscritto al sito di ISMEA?"
						name="ismea.isRegistered"
						options={yesnoOpts}
						required
					/>
					{ data?.ismea?.isRegistered === 'yes' && (
						<>
							<Input label="ISMEA - Nome utente" name="ismea.username" required />
							<Input label="ISMEA - Password" name="ismea.password" required />
						</>
					)}
				</Paper>

				<Paper sx={{ p:1, mb:1 }}>
					<Radio label="Consulente selezionato" name="consultant" options={consultantsOpts} />
				</Paper>
			</Form>
		</Tpl>
	)
}
