import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import { faMoneyCheckDollarPen } from '@fortawesome/pro-regular-svg-icons';

const tabDef = [
	{ label:"Data", field:'dateTS', type:'date' },
	{ label:"Voce", field:'description' },
	{ label:"Nota d'accredito", field:'isCreditedBack', type:'boolean', trueIcon:faMoneyCheckDollarPen, falseIcon:null, iconColor:'red' },
	{ label:"Importo", field:'grossAmount', type:'money' },
	{ label:"Incassato", type:'money', content:row =>
		row.isPaid ? row.grossAmount : 0
	},
	{ label:"Residuo", type:'money', content:row =>
		row.isPaid ? 0 : row.grossAmount
	},
	{ label:"Unità Op.", field:'opUnitTag' },
	// { label:"Residuo complessivo", field:'remainingTotal', type:'money' },
	// { label:"Residuo UPA", field:'remainingSRL', type:'money' },
	// { label:"Residuo SRL", field:'remainingUPA', type:'money' },
]

export default function AccountingYearsDetails() {
	const api = useContext(APICtx)
	const [ anagr, setAnagr ] = useState({})
	const [ payments, setPayments ] = useState([])
	const { customer, year } = useParams()
	
	const handleRefresh = () => {
		api.call('addrbook/get', { _id:customer }).then(setAnagr)
		api.call('payments/list', { customer, year:parseInt(year) }).then(setPayments)
	}
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('payments/get', { _id })
	
	const title = 'Pagamenti ' + year + ' - ' + (anagr?.label || '???')
	return (
		<Tpl title={title} backTo={"/accounting/summary/"+customer}>
			<DataList def={tabDef} ids={payments} getRow={getRow} />
		</Tpl>
	)
}
