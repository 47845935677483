import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx, { confApi } from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Dialog from '#Dialog'
import Search, { SearchText, SearchSelect } from '#Search'

import { faPlus, faEdit, faQrcode, faEnvelope, faPhoneRotary } from '@fortawesome/pro-regular-svg-icons'
// import EditForm from './Edit'
import EditForm from './ParticipantEdit'
import { QrDetails } from './QrDetails'
import { participantStates, referenceOptions } from './def'
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditParticipant = ({ _id, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh() : handleRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id===null}
			title={ (_id ? "Modifica" : "Creazione manuale") + " partecipazione" }
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}

const tabDef = [
	{ label:"Data/Ora rich.", field:'_created', type:'datetime' },
	{ label:"Azienda / Cod.Fisc.", content:row => (
		<>
			<Typography>{row.businessName}</Typography>
			<Typography variant='body2'>{row.taxCode}</Typography>
		</>
	)},
	{ label:"Partecipante", field:'participant' },
	{ label:"Contatti", content:row => (
		<>
			<Typography><FontAwesomeIcon icon={faEnvelope} /> {row.email}</Typography>
			<Typography><FontAwesomeIcon icon={faPhoneRotary} /> {row.phone}</Typography>
		</>
	)},
	{ label:"Riferimento", content:row => row?.reference?.toUpperCase() },
	{ label:"Cena?", content:row => row?.attend === 'all' ? (row?.menu === 'meat' ? 'SPIEDO' : 'VEGETARIANO') : '-' },
	{ label:"Stato", content:row =>
		participantStates.find(item => item.value===row.status)?.label || '???'
	},
	{ label:"Posto?", content:row => row?.seat || '-' },
	{ label:"#Scan", field:'scansCount' },
]

const csvDef = [
	{ label:"Data/Ora rich.", field:'_created', type:'datetime' },
	{ label:"Azienda", field:'businessName' },
	{ label:"Cod.Fisc.", field:'taxCode' },
	{ label:"Partecipante", field:'participant' },
	{ label:"Tel.", field:'phone' },
	{ label:"Email", field:'email' },
	{ label:"Riferimento", content:row => row?.reference?.toUpperCase() },
	{ label:"Cena?", content:row => row?.attend === 'all' ? (row?.menu === 'meat' ? 'SPIEDO' : 'VEGETARIANO') : '-' },
	{ label:"Stato", content:row =>
		participantStates.find(item => item.value===row.status)?.label || '???'
	},
	{ label:"Posto?", content:row => row?.seat || '-' },
	{ label:"#Scan", field:'scansCount' },
]

export default function EventList() {
	const api = useContext(APICtx)
	const { eventId } = useParams()
	const [ ids, setIds ] = useState([])
	const [ editParticipant, setEditParticipant ] = useState()
	const [ qrDetails, setQrDetails ] = useState()
	const [ searchString, setSearchString ] = useState('')
	const [ searchStatus, setSearchStatus ] = useState('')
	const [ searchReference, setSearchReference ] = useState('')
	const csvName = 'partecipanti_' + eventId

	const handleRefresh = () => api.call('event/participant/list', {
		eventId,
		searchString,
		searchStatus,
		searchReference,
	}).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ searchString, searchStatus, searchReference ])

	const getRow = _id => api.call('event/participant/get', { _id })

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditParticipant,
		},
		{
			icon: faQrcode,
			label: "QR d\'invito",
			onClick: row => setQrDetails({
				label: "QR d'invito",
				url: confApi.qrUrl + '/v/' + row.uuid,
				level: 'M',
			}),
			show: row => row.status === 'approved',
		},
	]

	return (
		<Tpl title="Lista partecipanti">
			<Search>
				<SearchText label="Cerca denominazione / C.Fisc. / partecipante" name="src-text" onChange={setSearchString} />
				<SearchSelect label="Stato" name="status" onChange={setSearchStatus} options={participantStates} emptyLabel="-- Tutti --" />
				<SearchSelect label="Riferimento" name="reference" onChange={setSearchReference} options={referenceOptions} emptyLabel="-- Tutti --" />
			</Search>
			<EditParticipant handleRefresh={handleRefresh} handleClose={() => setEditParticipant(null)} {...editParticipant} />
			<QrDetails handleClose={() => setQrDetails(null)} {...qrDetails} />
			<DataList def={tabDef} csvDef={csvDef} csvName={csvName} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} />
			<Fab label="Crea bando" icon={faPlus} onClick={() => setEditParticipant({ _id:null })} />
		</Tpl>
	)
}
