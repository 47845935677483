import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tooltip } from '@mui/material';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchAutoComplete, SearchSelect } from '#Search'
import DataList from '#DataList'
import SpeedDial from '#SpeedDial'

import UpdateWorkflow from './Update'
import Checklist from './Checklist'
import AddrbookItem from '../addrbook/AddrbookItem';

import { RecessoSocioCreate, RecessoSocioComm } from './RecessoSocio'
import { CommMorosiCreate, MorosiMessaInMora, MorosiDiffida, MorosiSospensione } from './CommMorosi'
import { EspulsioneCreate, EspulsioneComm } from './Espulsione'

import {
	faEdit,
	faMagnifyingGlass,
	faTrash,
	faListCheck,
	faBooks,
} from '@fortawesome/pro-regular-svg-icons'
import { faMessageDollar, faPersonToDoor, faUserMinus } from '@fortawesome/pro-solid-svg-icons';
import {
	workflowsDef,
} from './schemas'
import {
	Status,
	Config,
	States,
	AddrbookCommChannels,
} from './Widgets'
import ListConnectedDocs from '../documents/ListConnectedDocs';

const statusesOpts = [
	{ value:'active', label:'Attivi' },
	{ value:'closed', label:'Chiusi' },
	{ value:'all', label:'Tutti' },
]
const usableStatuses = [
	'running',
	'completed',
]

const tableDef = [
	{ label:"Numero", content:row => row._registry?.number },
	// { label:"A", content:row => row._actions.map(action => <p>{action}</p>) },
	{ label:"Tipo flusso", content:row =>
		// types.find(item => row.type === item.value)?.label || row.type
		workflowsDef?.[row.type]?.label || row.type
	},
	{ label:"Impostazioni", cellAlign:'center', content:row => <Config {...row} /> },
	{ label:"Stato", cellAlign:'center', content:row => <Status {...row} /> },
	{ content:async row => <AddrbookCommChannels _id={row?.addrbookItem} />},
	{ label:"Anagrafica", content:async row => <AddrbookItem _id={row?.addrbookItem} />},
	{ label:"Attività", cellAlign:'center', content:row => <States type={row.type} states={row?.states} /> },
]

export default function WorkflowsList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ updateWorkflow, setUpdateWorkflow ] = useState()
	const [ createType, setCreateType ] = useState()
	const [ customAction, setCustomAction ] = useState({})
	const [ checklist, setChecklist ] = useState()
	const [ connectedDocsSource, setConnectedDocsSource ] = useState()
	const [ filterStatus, setFilterStatus ] = useState(statusesOpts[0].value)
	const [ filterAddrbookItem, setFilterAddrbookItem ] = useState()

	const fetchAddrbookOptions = async searchString =>
		api.call('addrbook/list-options', {
			type: '003',
			searchString,
		})
	const fetchAddrbookLabel = async _id => {
		const item = await api.call('addrbook/get', { _id })
		return item?.label + ' - ' + item?.taxCode
	}

	const handleAbort = ({ _id, _registry, handleRowRefresh }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler annullare il flusso "' + _registry?.number + '"?',
			onConfirm: () => api.call('workflow/abort', { _id })
				.then(handleRowRefresh),
		})
	}
	const handleTrigger = ({ _id, _registry, label, action, handleRowRefresh }) => {
		api.dialogConfirm({
			text: label + ' sul flusso ' + _registry?.number + '?',
			onConfirm: () => api.call('workflow/trigger', { _id, action }).then(handleRowRefresh),
		})
	}
	const handleRefresh = () =>
		api.call('workflow/list', { filterStatus, filterAddrbookItem }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ filterStatus, filterAddrbookItem ])

	const handleUpdateClose = async () =>
		setUpdateWorkflow(null)
	const handleCreateClose = async () => {
		await handleRefresh()
		setCreateType(null)
	}

	const getRow = _id => api.call('workflow/get', { _id })
	const defBtns = [
		{
			icon: api.auth.chk('workflows-admin') ? faEdit : faMagnifyingGlass,
			label: 'Dati flusso',
			onClick: setUpdateWorkflow,
		},
		{
			icon: faBooks,
			label: 'Documenti',
			onClick: row => setConnectedDocsSource(row._id),
		},
	]
	api.auth.chk('workflows-admin')
		defBtns.push({
			icon: faListCheck,
			label: 'Riepilogo eventi',
			onClick: setChecklist,
		})
	Object.entries(workflowsDef).forEach(([ workflowTag, workflowDef ]) => {
		Object.entries(workflowDef.actions).forEach(([ actionName, actionDef ]) => {
			defBtns.push({
				icon: actionDef.icon,
				icon2: actionDef.icon2,
				label: actionDef.label,
				onClick: row => actionDef.customAction ?
					setCustomAction({
						...row,
						_tag: actionDef.customAction,
					}) : handleTrigger({
						_id: row._id,
						_registry: row._registry,
						label: actionDef.label,
						action: actionName,
						handleRowRefresh: row.handleRowRefresh,
					}),
				show: row => workflowTag === row.type && usableStatuses.includes(row.status) && row._actions.includes(actionName),
			})
		})
	})
	api.auth.chk('workflows-admin') && defBtns.push({
		icon: faTrash,
		label: 'Annulla',
		onClick: handleAbort,
		show: row => row.status === 'running',
	})

	// const speedActions2 = [
	// 	{ tooltip:'Recesso socio', icon:faUserMinus, onClick:() => setCreateType('recesso-socio') },
	// 	{ tooltip:'Comunicazione ai soci morosi', icon:faMessageDollar, onClick:() => setCreateType('comunicazione-morosi') },
	// 	{ tooltip:'Espulsione', icon:faPersonToDoor, onClick:() => setCreateType('espulsione') },
	// ]
	const speedActions = Object.keys(workflowsDef)
		.map(workflowTag => ({
			tooltip: workflowsDef[workflowTag].label,
			icon: workflowsDef[workflowTag].icon,
			onClick:() => setCreateType(workflowTag),
		}))

	return (
		<Tpl title="Flussi di lavoro">
			<UpdateWorkflow {...updateWorkflow} handleClose={handleUpdateClose} />
			<Checklist {...checklist} handleClose={() => setChecklist(null)} />
			<ListConnectedDocs sourceId={connectedDocsSource} handleClose={() => setConnectedDocsSource(null)} />

			<RecessoSocioCreate open={createType === 'recesso-socio'} handleClose={handleCreateClose} />
			<RecessoSocioComm
				{...customAction}
				open={customAction?._tag === 'recesso-socio-comm'}
				handleClose={() => setCustomAction({})}
			/>

			<CommMorosiCreate open={createType === 'comunicazione-morosi'} handleClose={handleCreateClose} />
			<MorosiMessaInMora
				{...customAction}
				open={customAction?._tag === 'morosi-messa-in-mora'}
				handleClose={() => setCustomAction({})}
			/>
			<MorosiDiffida
				{...customAction}
				open={customAction?._tag === 'morosi-diffida'}
				handleClose={() => setCustomAction({})}
			/>
			<MorosiSospensione
				{...customAction}
				open={customAction?._tag === 'morosi-sospensione'}
				handleClose={() => setCustomAction({})}
			/>

			<EspulsioneCreate open={createType === 'espulsione'} handleClose={handleCreateClose} />
			<EspulsioneComm
				{...customAction}
				open={customAction?._tag === 'espulsione-comm'}
				handleClose={() => setCustomAction({})}
			/>

			<Search>
				<SearchSelect label="Stato" name="src-status" onChange={setFilterStatus} options={statusesOpts} defaultValue={statusesOpts[0].value} />
				<SearchAutoComplete
					label="Cliente"
					name="src-addrbook"
					onChange={setFilterAddrbookItem}
					fetchOptions={fetchAddrbookOptions}
					fetchLabel={fetchAddrbookLabel}
				/>
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			<SpeedDial actions={speedActions} />
		</Tpl>
	)
}
