import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Search, { SearchSelect, SearchText } from '#Search'

import { faPlus, faEdit, faMagnifyingGlass, faFilePdf } from '@fortawesome/pro-regular-svg-icons'

import consultantsOpts from './consultants.json'

const tabDef = [
	{ label:"Numero", content:row => row?._registry?.number },
	{ label:"Ragione sociale", field:'companyName' },
	{ label:"Consulente", content:row =>
		consultantsOpts.find(consultant => consultant.value===row.consultant)?.label || '???'
	},
]

export default function IsmeaList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ srcString, setSrcString ] = useState('')
	const [ srcConsultant, setSrcConsultant ] = useState('')

	const handleRefresh = () => api.call('ismea/list', { srcString, srcConsultant }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ srcString, srcConsultant ])

	const handlePreviewPdf = ({ _id }) => api.openFile({ tag:'ismea/get/pdf', params:{ _id } })
	const handleDownloadPdf = ({ _id, _registry }) =>
		api.openFile({
			tag:'ismea/get/pdf',
			params:{ _id },
			download: 'bando-ismea-' + _registry.number + '.pdf',
		})

	const getRow = _id => api.call('ismea/get/brief', { _id })

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => "/ismea/item/"+row._id,
		},
		{ icon:faMagnifyingGlass, label:'Anteprima PDF', onClick:handlePreviewPdf },
		{ icon:faFilePdf, label:'Scarica PDF', onClick:handleDownloadPdf },
	]
	
	return (
		<Tpl title="Lista bandi ISMEA">
			<Search>
				<SearchText label="Cerca azienda" name="src-text" onChange={setSrcString} />
				<SearchSelect label="Consulente" name="src-consultant" onChange={setSrcConsultant} options={consultantsOpts} emptyLabel="-- Tutti --" />
			</Search>
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} />
			<Fab label="Crea bando" icon={faPlus} linkTo="/ismea/item" />
		</Tpl>
	)
}
