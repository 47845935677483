import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import Form, { Input, InputMulti, Checkbox, Select } from '#Form'

export default function DetailsEdit({ _id }) {
	const api = useContext(APICtx)
	const [ zones, setZones ] = useState([])

	useEffect(() => {
		api.call('zone/options').then(zones => setZones(
			zones.map(zone => ({
				value: zone.zid,
				label: zone.label,
			}))
		))
	}, [])

	const formDef = {
		_id,
		callSet: "addrbook/set-details",
		callGet: _id && "addrbook/get",
	}

	return (
		<Form {...formDef}>
			<Select label="Zona" name="extras.zoneZID" options={zones} emptyLabel="--- nessuna zona associata ---" disabled />
			<InputMulti label="Telefono" name="contacts.phone" disabled />
			<InputMulti label="Cellulare" name="contacts.mobile" disabled />
			<InputMulti label="Email" name="contacts.email" disabled />
			<Input label="PEC" name="contacts.pec" disabled />
			<Input label="Note" name="notes" multiline />
			{ api.auth.chk('inps-delegation-rw') && <Checkbox label="Delega INPS?" name="inpsDelegation" /> }
		</Form>
	)
}
